import React from "react";
import Routers from "./routers";
import {
  ThirdwebProvider,
  metamaskWallet,
  trustWallet,
  walletConnect,
  okxWallet,
  coinbaseWallet,
  useAddress,
} from "@thirdweb-dev/react";
import { Polygon } from "@thirdweb-dev/chains";

function BlockedWalletChecker({ children }) {
  const address = useAddress();

  // Lista de direcciones bloqueadas
  const blockedAddressesString = "0x71ef04391131b3529515990a9887663137cd6208, 0xd3ab171ccc344a489abda15a692d6815268d0094";

  // Convertir la cadena en un array de direcciones
  const blockedAddresses = blockedAddressesString
    .split(",")
    .map(addr => addr.trim().toLowerCase());

  // Verifica si la dirección conectada está en la lista de direcciones bloqueadas
  if (address && blockedAddresses.includes(address.toLowerCase())) {
    return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className="blog__item">
          <p style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>YOUR ACCOUNT HAS BEEN BLOCKED</p>
          <p style={{ fontSize: '18px', textAlign: 'center' }}>REASON: Devaluation of Goblin NFTs in Minting Phase.</p>
          <p style={{ fontSize: '18px', textAlign: 'center' }}>MARKET: Magic Eden.</p>
          <p style={{ fontSize: '14px', textAlign: "center" }}>
            We hope you understand, we want to protect our community holder's investment.
            If you think this is a mistake please contact Discord support.
          </p>
          <div>
            <p style={{ fontSize: '14px', textAlign: 'center' }}>Learn more about this</p>
            <p style={{ fontSize: '14px', textAlign: 'center' }}>
              <a href="https://docs.goblinsaga.xyz/ecosystem-overview/protection-and-measures">
                Market Protection and Stability Measures
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return children;
}

function App() {
  return (
    <ThirdwebProvider
      autoConnect={true}
      activeChain={Polygon}
      supportedChains={[Polygon]}
      supportedWallets={[
        metamaskWallet(),
        trustWallet(),
        okxWallet(),
        coinbaseWallet(),
        walletConnect(),
      ]}
      clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID} // Ajusta la variable de entorno
    >
      <BlockedWalletChecker>
        <Routers />
      </BlockedWalletChecker>
    </ThirdwebProvider>
  );
}

export default App;
